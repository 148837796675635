<template>
  <div class="AlreadyHaveAccount d-flex pr-4">
    <div class="pr-2 emobg-color-ink">
      {{ $t('refactor.user_signup.general.already_account') }}
    </div>
    <div>
      <span
        class="emobg-color-primary emobg-link emobg-font-weight-semibold"
        @click="goLogin"
      >
        {{ $t('refactor.user_signup.general.login') }}
      </span>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router/composables';
import { useTheme } from '@/composable/Theme/useTheme';

const router = useRouter();

const { fetchButtonSpecs } = useTheme();

defineExpose({
  fetchButtonSpecs,
});

function goLogin() {
  window.location.href = router.resolve('/').href;
}
</script>
